import React from "react";
import { Route, Routes } from 'react-router-dom';
import HeaderMenu from "./HeaderMenu";
import About from "./pages/about/About";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import { OAuthResultGoogle, OAuthResultLine } from "./pages/login/OAuthResult";
import MemberInfo from "./pages/member-info/MemberInfo";
import PasswordForgetting from "./pages/password-forgetting/PasswordForgetting";
import Policy from "./pages/policy/Policy";
import Register from "./pages/register/Register";
import { bannerHeight } from "./css/themeStyles";

export const DesktopLayout = (props) => {
    return (
        <div>
            <HeaderMenu />
            <div style={{ paddingTop: bannerHeight }}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/Login" element={<Login />} />
                    <Route path="/MemberInfo" element={<MemberInfo />} />
                    <Route path="/Register" element={<Register />} />
                    <Route path="/Policy" element={<Policy />} />
                    <Route path="/PasswordForgetting" element={<PasswordForgetting />} />
                    <Route path="/OAuthResultLine" element={<OAuthResultLine queryString={props.query} />} />
                    <Route path="/OAuthResultGoogle" element={<OAuthResultGoogle queryString={props.query} />} />
                    {/* <Route path="/Pricing" component={Link} element={<Pricing />} /> */}
                    {/* <Route path="/Newebpay" component={Link} element={<Newebpay />} /> */}
                    {/* <Route path="/TradeResult" component={Link} element={<TradeResult queryString={props.query} />} />  */}
                    {/* <Route path="/TempEnv" element={<TempEnv />} /> */}
                    {/* <Route path="/PositionDisplay" element={<PositionDisplay />} /> */}
                </Routes>
            </div>
        </div>
    );
}

export default React.memo(DesktopLayout);