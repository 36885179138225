import { createTheme } from '@mui/material/styles';

export const themeMui = createTheme({
  components: {
    // MuiAppBar: {
    //   defaultProps: {
    //     size: 'small',
    //   },
    //   styleOverrides: {
    //     root: {
    //       // fontSize: '1rem', // 修改按钮的字体大小
    //       // padding: '0.5rem 0.5rem', // 修改按钮的内边距
    //     }
    //   }
    // },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          // fontSize: '1rem', // 修改按钮的字体大小
          // padding: '0.5rem 0.5rem', // 修改按钮的内边距
        }
      }
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          // fontSize: '1rem', // 修改按钮的字体大小
          // padding: '0.5rem 0.5rem', // 修改按钮的内边距
        }
      }
    },
    MuiAutocomplete: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          // fontSize: '0.8rem', // 修改按钮的字体大小
          // padding: '0.5rem 0.5rem', // 修改按钮的内边距
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          // fontSize: '1rem', // 修改按钮的字体大小
          // padding: '0.5rem 0.5rem', // 修改按钮的内边距
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          // fontSize: '0.6rem', // 修改按钮的字体大小
          // padding: '0.4rem 0.5rem' // 修改按钮的内边距
        }
      }
    }
  }
});

export default themeMui;
