import { useNavigate } from 'react-router-dom';
import { useAPI } from '../../context/APIContext.jsx';
import { apiUris } from '../../config/Api';
import { useEffect } from 'react';

export function OAuthResultLine(props) {
    const navigate = useNavigate();
    const code = props.queryString.get('code');
    const { handleLoginStatus, post } = useAPI();

    useEffect(() => {

        loginByLine(code);

    }, [])

    const loginByLine = async (code) => {
        const resData = await post(apiUris.memberLoginByLine, { code });
        if (resData.code === 0) {
            handleLoginStatus(true, resData);
            navigate('/', { replace: true });
        }
        else {
            navigate('/Login', { replace: false });
        }
    }
}

export function OAuthResultGoogle(props) {
    const navigate = useNavigate();
    const code = props.queryString.get('code');
    const { handleLoginStatus, post } = useAPI();

    useEffect(() => {

        loginByGoogle(code);

    }, [])


    const loginByGoogle = async (code) => {
        const resData = await post(apiUris.memberLoginByGoogle, { code });
        if (resData.code === 0) {
            handleLoginStatus(true, resData);
            navigate('/', { replace: true });
        }
        else {
            navigate('/Login', { replace: false });
        }
    }
}

export default OAuthResultLine;