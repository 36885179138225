import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { countyList, districtList } from '../../config/Address';

export const Address = ({
    defCounty = 'null',
    defDistrict = 'null',
    defAddress = '',
    handleChange,
    handleMuitChange,
    GridColumnSpacing = { xs: 1, sm: 2, md: 3 },
    GridItemSize = { county: { xs: 12, sm: 3, md: 3 }, district: { xs: 12, sm: 3, md: 3 }, address: { xs: 12, sm: 3, md: 3 } }
}) => {
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [county, setCounty] = useState(defCounty);
    const [district, setDistrict] = useState(defDistrict);
    const [address, setAddress] = useState(defAddress);

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
            return;
        }; // 如果是初始加载，不执行

        setDistrict('null');

        handleMuitChange([
            { name: "county", value: county },
            { name: "district", value: null }
        ]);
    }, [county]);

    const handleCountyChange = (event) => {
        setCounty(event.target.value);
    };

    const handleDistrictChange = (event) => {
        setDistrict(event.target.value);
        // handleChange(event);

        const newDistrict = event.target.value !== 'null' ? event.target.value : null;
        handleMuitChange([
            { name: "district", value: newDistrict }
        ]);
    };

    const handleAddressChange = (event) => {
        setAddress(event.target.value);
        handleChange(event);
    }

    return (
        <Grid container rowSpacing={1.5} columnSpacing={GridColumnSpacing} padding={0}>
            <Grid item xs={GridItemSize.county.xs} sm={GridItemSize.county.sm} md={GridItemSize.county.md}>
                <FormControl fullWidth>
                    <InputLabel id="county-select-label">縣市</InputLabel>
                    <Select
                        required
                        name="county"
                        labelId="county-select-label"
                        id="county-select"
                        label="縣市"
                        value={county}
                        onChange={handleCountyChange}
                    >
                        {Object.entries(countyList).map(([key, value]) => (
                            <MenuItem key={value} value={value}>{key}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={GridItemSize.district.xs} sm={GridItemSize.district.sm} md={GridItemSize.district.md}>
                <FormControl fullWidth>
                    <InputLabel id="district-select-label">行政區</InputLabel>
                    <Select
                        required
                        name="district"
                        labelId="district-select-label"
                        id="district-select"
                        label="行政區"
                        value={district}
                        onChange={handleDistrictChange}
                    >
                        {county && Object.entries(districtList[county]).map(([key, value]) => (
                            <MenuItem key={value} value={value}>{key}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={GridItemSize.address.xs} sm={GridItemSize.address.sm} md={GridItemSize.address.md}>
                <FormControl fullWidth>
                    <TextField fullWidth required
                        name="address"
                        label="地址"
                        value={address}
                        onChange={handleAddressChange}
                        placeholder="中山北路一段100號"
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
}

export default React.memo(Address);