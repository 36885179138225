import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import '../../css/styles.css';

const PriceGovResultDetail = ({
    list
}) => {

    const priceGovColumnsWidth = {
        //window.innerWidth 裝置的視窗的內部寬度px（不包括滾動條）
        //0.45代表此component 佔據視窗的比例
        no: window.innerWidth * 0.46 * 0.125,
        deal_date: window.innerWidth * 0.46 * 0.2,
        shifting_floor: window.innerWidth * 0.46 * 0.15,
        unit_price_tw: window.innerWidth * 0.46 * 0.15,
        building_shifting_total_tw: window.innerWidth * 0.46 * 0.15,
        build_state: window.innerWidth * 0.46 * 0.25,
    }

    const priceGovColumns = [
        // { field: 'id', headerName: 'ID', width: 70, hide: true },
        {
            field: 'no',
            headerName: '',
            width: priceGovColumnsWidth.no,
            sortable: false,
            renderCell: (params) => {
                // 得到所有已排序和過濾後的行 ID
                const allRows = params.api.getSortedRowIds();
                // 找出當前行 ID 在已排序列表中的索引，加 1 以顯示從 1 開始的序號
                const no = allRows.indexOf(params.id) + 1;
                return <span>{no}</span>;
            }
        },
        { field: 'deal_date', headerName: '交易日期', width: priceGovColumnsWidth.deal_date, disableColumnMenu: true, cellClassName: 'wrapText' },
        { field: 'shifting_floor', headerName: '樓層', width: priceGovColumnsWidth.shifting_floor, disableColumnMenu: true },
        { field: 'unit_price_tw', headerName: '單價(萬元)', width: priceGovColumnsWidth.unit_price_tw, type: 'number' },
        { field: 'building_shifting_total_tw', headerName: '權狀坪', width: priceGovColumnsWidth.building_shifting_total_tw, type: 'number' }
    ];

    return (
        <DataGrid
            columns={priceGovColumns}
            rows={list}
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                },
            }}
            pageSizeOptions={[5, 10]}
            getRowHeight={() => 'auto'} // 設置行高自動調整
        />
    );
}

export default React.memo(PriceGovResultDetail);