import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, IconButton } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider/Divider.js';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useData } from '../../context/DataContext';

const Env = ({
    handleMuitChange,
    GridColumnSpacing = { xs: 1, sm: 2, md: 3 },
    GridItemSize = { xs: 6, sm: 6, md: 6 },
}) => {
    const [envCategories, setEnvCategories] = useState(null);
    // const { post } = useAPI();
    const { categoryFilterItem } = useData();
    const [envCollapseOpen, setEnvCollapseOpen] = useState(true);

    useEffect(() => {
        // envCategories 資料結構範例
        // const newEnvCategories = [
        //     { name: "交通", category: "/交通", checked: false, hasChildren: true, collapseOpen: false },
        //     { name: "捷運", category: "/交通/捷運", checked: false, hasChildren: false, collapseOpen: false },
        //     //{ name: "台北捷運", category: "/交通/捷運/台北捷運", checked: false, hasChildren: false, collapseOpen: false },
        //     { name: "台鐵", category: "/交通/台鐵", checked: false, hasChildren: false, collapseOpen: false },
        //     { name: "超商", category: "/超商", checked: false, hasChildren: true, collapseOpen: false },
        //     { name: "7-11", category: "/超商/7-11", checked: false, hasChildren: false, collapseOpen: false },
        //     { name: "全家", category: "/超商/全家", checked: false, hasChildren: false, collapseOpen: false },
        //     { name: "OK", category: "/超商/OK", checked: false, hasChildren: false, collapseOpen: false }
        // ];

        //useEffect 中不能夠使用 await 所以建立一個 async function 承接API 回傳值
        // const getEnvFilterItem = async () => {
        //     const newEnvCategories = await post(apiUris.getEnvFilterItem);
        //     setEnvCategories(newEnvCategories.filter_item);
        // }

        // // 執行一次特定的程式碼，等待 2000 毫秒（2 秒）
        // do {
        //     setTimeout(() => {
        //         if (categoryFilterItem !== null) {
        //             setEnvCategories(categoryFilterItem);
        //         }
        //     }, 2000);

        //     // return () => {
        //     //     clearTimeout(timerId); // 清理計時器
        //     // };
        // } while (categoryFilterItem === null)

        if (categoryFilterItem !== null) {
            setEnvCategories([...categoryFilterItem]);
        }
    }, [categoryFilterItem])

    const handleCollapseOpenToggle = (event) => {
        const newEnvCategories = envCategories.map(item => {
            if (item.name === event.currentTarget.dataset.name) {
                item.collapseOpen = !item.collapseOpen;
            }
            return item;
        });
        setEnvCategories(newEnvCategories);
    };

    const handleEnvCollapseOpenToggle = (event) => {
        setEnvCollapseOpen(!envCollapseOpen);
    }

    const handleEnvCollapseOpenIconToggle = (event) => {
        event.stopPropagation();  // 阻止事件冒泡
        setEnvCollapseOpen(!envCollapseOpen);
    }

    const handleChecked = (event) => {
        const { value, checked } = event.currentTarget;
        const newEnvCategories = envCategories.map(item => {
            if (item.name === value) {
                item.checked = checked;
            }
            return item;
        });
        const current = newEnvCategories.find(x => x.name === value);
        handleParentChecked(current, newEnvCategories);
        handleChildrenChecked(current, newEnvCategories);

        setEnvCategories(newEnvCategories);
        handleEnvChange(newEnvCategories);
    }

    const handleEnvChange = (newEnvCategories) => {
        const newEnv = [];

        newEnvCategories.forEach(item => {
            if (item.checked && item.hasChildren === false) {
                newEnv.push(item.category);
            }
        });

        handleMuitChange([
            { name: "env_categories", value: newEnv },
        ]);
    };

    const handleParentChecked = (current, newEnvCategories) => {
        const parentCategory = current.category.replace(`/${current.name}`, ""); //ex:/交通/台鐵 => /交通

        if (parentCategory === "") return; //判斷上面是否有父選項

        const siblingCheckedList = []; //同層級的其他選項checked狀態
        let parentOriginalChecked = null; //父層級原本的checked狀態

        newEnvCategories.map(item => {
            if (item.category === parentCategory) {
                parentOriginalChecked = item.checked; //紀錄父選項原本的checked狀態
                return;
            }
            if (item.category.includes(parentCategory)) { //紀錄同層級其他選項的checked狀態
                siblingCheckedList.push(item.checked);
            }
            return item;
        });

        const parentChecked = siblingCheckedList.every(x => x === true); //判斷同層級選項全是true，父選項更新為true

        if (parentOriginalChecked === parentChecked) return; //父選項狀態沒變，就不必向上判斷checked狀態

        newEnvCategories.map(item => { //更新集合中父選項checked狀態
            if (item.category === parentCategory) {
                item.checked = parentChecked;
            }
            return item;
        });

        const parent = newEnvCategories.find(x => x.category === parentCategory);

        handleParentChecked(parent, newEnvCategories);
    }

    const handleChildrenChecked = (current, newEnvCategories) => {
        newEnvCategories.map(item => {
            if (item.category === current.category) {
                return;
            }
            if (item.category.includes(current.category)) {
                item.checked = current.checked;
            }
            return item;
        });
    }

    const renderEnvCategories = (current, categoryLevel) => (
        <Grid container rowSpacing={1} columnSpacing={GridColumnSpacing}>
            {envCategories.filter(x => x.category.includes(current.category)).map(item => ( //取得上一個層級的選項來取得子選項
                (item.category.match(/\//g).length === (categoryLevel + 1)) && //用幾條"/"判斷選項層級
                <Grid item xs={GridItemSize.xs} sm={GridItemSize.sm} md={GridItemSize.md} key={item.name}>
                    <FormControlLabel
                        control={<Checkbox inputProps={{ 'data-category': item.category }} />}
                        value={item.name}
                        checked={item.checked}
                        onChange={handleChecked}
                        sx={{ marginRight: '0px' }}
                        label={<><img src={`/marker/${item.name}.svg`} alt={item.name} style={{ width: '20px', marginRight: '5px', verticalAlign: 'middle' }} />{item.name}</>} />
                    {item.hasChildren && (
                        <span>
                            <IconButton
                                onClick={handleCollapseOpenToggle}
                                data-name={item.name}
                                sx={{ height: 30 }}>
                                {item.collapseOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                            <Collapse in={item.collapseOpen} timeout="auto" unmountOnExit>
                                {
                                    renderEnvCategories(item, categoryLevel)
                                }
                            </Collapse>
                        </span>
                        //renderEnvCategories(item, categoryLevel)
                    )}
                </Grid>
            ))}
        </Grid>
    )

    return (
        <>
            <Divider textAlign="center">
                <Typography sx={{ cursor: 'pointer' }} onClick={handleEnvCollapseOpenToggle}>機能查詢
                    <IconButton
                        onClick={handleEnvCollapseOpenIconToggle}
                        sx={{ height: 30 }}>
                        {envCollapseOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Typography>
            </Divider>
            <Collapse in={envCollapseOpen} timeout="auto" unmountOnExit>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} paddingLeft={1}>
                    {envCategories && envCategories.map((item) => (
                        (item.category.match(/\//g).length === 1) && //用一條"/"來判斷根選項ex:/交通 
                        <Grid item xs={12} sm={12} md={12} key={item.name}>
                            <FormControlLabel
                                control={<Checkbox inputProps={{ 'data-category': item.category }} />}
                                value={item.name}
                                checked={item.checked}
                                onChange={handleChecked}
                                sx={{ marginRight: '0px' }}
                                label={item.hasChildren
                                    ? item.name
                                    : <><img src={`/marker/${item.name}.svg`} alt={item.name} style={{ width: '20px', marginRight: '5px', verticalAlign: 'middle' }} />{item.name}</>
                                } />
                            {item.hasChildren &&
                                <span>
                                    <IconButton
                                        onClick={handleCollapseOpenToggle}
                                        data-name={item.name}
                                        sx={{ height: 30 }}>
                                        {item.collapseOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                    <Collapse in={item.collapseOpen} timeout="auto" unmountOnExit>
                                        {
                                            renderEnvCategories(item, item.category.match(/\//g).length)
                                        }
                                    </Collapse>
                                </span>
                            }
                            <Divider textAlign="left" sx={{ Margin: '10px' }} />
                        </Grid>
                    ))}
                </Grid>
            </Collapse>
        </>
    );
}

export default React.memo(Env);