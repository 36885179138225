import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, IconButton } from '@mui/material';
import Divider from '@mui/material/Divider/Divider.js';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Hidden from '@mui/material/Hidden';
import { buildStateList, shiftingFloorList, buildingPatternRoomList, ageList, buildingShiftingTotalTwList } from '../../config/PriceGov';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const PriceGov = ({
    formData,
    handleMuitChange,
    GridColumnSpacing = { xs: 1, sm: 2, md: 3 },
    GridItemSize = { xs: 12, sm: 6, md: 6 },
    GridItemSizeRange = { first: { xs: 12, sm: 5.5, md: 5.5 }, Middle: { xs: 0, sm: 1, md: 1 }, last: { xs: 12, sm: 5.5, md: 5.5 } }
}) => {
    const [data, setData] = useState(formData.price_govs);
    const [collapseOpen, setCollapseOpen] = useState(true);

    const handleEnvCollapseOpenToggle = (event) => {
        setCollapseOpen(!collapseOpen);
    }

    const handleEnvCollapseOpenIconToggle = (event) => {
        event.stopPropagation();  // 阻止事件冒泡
        setCollapseOpen(!collapseOpen);
    }

    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        const newData = { ...data, [name]: value };
        setData(newData);

        let postValue;
        if (value === 'null') {
            postValue = null;
        }
        // else if (value === 0) {
        //     postValue = null;
        // }
        else {
            postValue = value;
        }
        const newPostData = { ...data, [name]: postValue };

        handleMuitChange([
            { name: "price_govs", value: newPostData },
        ]);
    };

    return (
        <>
            <Divider textAlign="center">
                <Typography sx={{ cursor: 'pointer' }} onClick={handleEnvCollapseOpenToggle}>實價登錄查詢
                    <IconButton
                        onClick={handleEnvCollapseOpenIconToggle}
                        sx={{ height: 30 }}>
                        {collapseOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Typography>
            </Divider>
            <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
                <Grid container
                    rowSpacing={1.5}
                    columnSpacing={GridColumnSpacing}
                    marginTop={1}
                >
                    <Grid item xs={GridItemSize.xs} sm={GridItemSize.sm} md={GridItemSize.md}>
                        <FormControl fullWidth >
                            <TextField fullWidth disabled required
                                name="deal_date_start"
                                id="deal_date_start"
                                label="交易日期~至今"
                                value={data.deal_date_start}
                                placeholder="2019-01-01"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={GridItemSize.xs} sm={GridItemSize.sm} md={GridItemSize.md}>
                        <FormControl fullWidth required>
                            <InputLabel id="age_select_label">屋齡</InputLabel>
                            <Select
                                name="age"
                                labelId="age_select_label"
                                id="age"
                                label="屋齡"
                                value={data.age}
                                onChange={handleSelectChange}
                            >
                                {Object.entries(ageList).map(([key, value]) => (
                                    <MenuItem key={value} value={value}>{key}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={GridItemSize.xs} sm={GridItemSize.sm} md={GridItemSize.md}>
                        <FormControl fullWidth required>
                            <InputLabel id="building_shifting_total_tw_select_label">權狀坪</InputLabel>
                            <Select
                                name="building_shifting_total_tw"
                                labelId="building_shifting_total_tw_select_label"
                                id="building_shifting_total_tw"
                                label="權狀坪"
                                value={data.building_shifting_total_tw}
                                onChange={handleSelectChange}
                            >
                                {Object.entries(buildingShiftingTotalTwList).map(([key, value]) => (
                                    <MenuItem key={value} value={value}>{key}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={GridItemSize.xs} sm={GridItemSize.sm} md={GridItemSize.md}>
                        <FormControl fullWidth>
                            <InputLabel id="building_state_select_label">建物類型</InputLabel>
                            <Select
                                name="building_state"
                                labelId="building_state_select_label"
                                id="building_state"
                                label="建物類型"
                                value={data.building_state}
                                onChange={handleSelectChange}
                            >
                                {Object.entries(buildStateList).map(([key, value]) => (
                                    <MenuItem key={value} value={value}>{key}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* <Grid item xs={GridItemSize.xs} sm={GridItemSize.sm} md={GridItemSize.md}>
                        <FormControl fullWidth>
                            <InputLabel id="building_pattern_room_select_label">房型</InputLabel>
                            <Select
                                name="building_pattern_room"
                                labelId="building_pattern_room_select_label"
                                id="building_pattern_room"
                                label="房型"
                                value={data.building_pattern_room}
                                onChange={handleSelectChange}
                            >
                                {Object.entries(buildingPatternRoomList).map(([key, value]) => (
                                    <MenuItem key={value} value={value}>{key}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={GridItemSize.xs} sm={GridItemSize.sm} md={GridItemSize.md}>
                        <FormControl fullWidth>
                            <InputLabel id="shifting_floor_select_label">樓層</InputLabel>
                            <Select
                                name="shifting_floor"
                                labelId="shifting_floor_select_label"
                                id="shifting_floor"
                                label="樓層"
                                value={data.shifting_floor}
                                onChange={handleSelectChange}
                            >
                                {Object.entries(shiftingFloorList).map(([key, value]) => (
                                    <MenuItem key={value} value={value}>{key}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid> */}
                </Grid>
            </Collapse>
        </>
    );
}

export default React.memo(PriceGov);