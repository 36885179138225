import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import { useAPI } from "../../context/APIContext";
import { useData } from "../../context/DataContext";
import { LoadingOverlay } from '../../components/loading/Loading';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { likeLevelList } from '../../config/Report';
import { priceGovProportion } from '../../config/PriceGov';
import apiUri from '../../config/Api.js';
import Divider from '@mui/material/Divider/Divider.js';
import Typography from '@mui/material/Typography';
import { Collapse, IconButton } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export const ReportDialog = ({ dialogShow, handleClose }) => {
    const { storeReport } = useData();
    const { loading, post, showAlert, getCookie } = useAPI();
    const { condition, result } = useData();
    const [collapsePriceGov, setCollapseOpenPriceGov] = useState(true);
    const [collapseEnv, setCollapseOpenEnv] = useState(true);
    const [reportParam, setReportParam] = useState({
        condition: null,
        result: null,
        price_score_proportion: null,
        env_score_proportion: null,
        env_category_weight_list: null,
        target_object: null
    });
    const [formData, setFormData] = useState({
        member_id: getCookie("member_id") === null ? "" : getCookie("member_id"),
        email: getCookie("email") === null ? "" : getCookie("email"),
        param: null
    });

    useEffect(() => {
        const newReportParam = {};
        newReportParam.condition = condition;
        newReportParam.result = result;
        newReportParam.target_object = (result.places && result.places.length > 0)
            ? {
                unit_price_tw: ""
            }
            : null

        if (result.env_infos && result.env_infos !== undefined && result.env_infos.length > 0) {
            // console.log(envRows);
            newReportParam.env_category_weight_list = result.env_infos.reduce((acc, current) => {
                // 檢查是否已經存在相同 name 的項目
                if (!acc.some(item => item.name === current.name)) {
                    acc.push({ name: current.name, weight: 1 });
                }
                return acc;
            }, []);
        }

        if (newReportParam.target_object &&
            (newReportParam.env_category_weight_list && newReportParam.env_category_weight_list.length > 0)) {
            newReportParam.price_score_proportion = 0.6;
            newReportParam.env_score_proportion = 0.4;
        }
        else if (newReportParam.target_object &&
            (!newReportParam.env_category_weight_list || newReportParam.env_category_weight_list.length == 0)) {
            newReportParam.price_score_proportion = 1;
            newReportParam.env_score_proportion = 0;
        }
        else if (!newReportParam.target_object &&
            (newReportParam.env_category_weight_list && newReportParam.env_category_weight_list.length > 0)) {
            newReportParam.price_score_proportion = 0;
            newReportParam.env_score_proportion = 1;
        }

        setReportParam(newReportParam);
    }, [condition, result])

    const handleUnitPriceTwChange = (event) => {
        const { value } = event.target;
        const newReportParam = { ...reportParam };
        let newUnitPriceTw = parseInt(value);
        if (isNaN(newUnitPriceTw)) {
            newUnitPriceTw = null; // 如果解析失敗，返回預設值
            showAlert("請輸入整數，欄位：欲購買物件單價", "error");
        }
        newReportParam.target_object.unit_price_tw = newUnitPriceTw;
        setReportParam(newReportParam);
    }

    const handleEnvSelectChange = (event) => {
        const { name, value } = event.target;
        const newReportParam = { ...reportParam };
        const env_category_weight_list = reportParam.env_category_weight_list.map((item) => {
            if (item.name === name) {
                item.weight = value;
            }
            return item;
        });
        newReportParam.env_category_weight_list = env_category_weight_list;
        setReportParam(newReportParam);
    }

    const handleScoreProportionSelectChange = (event) => {
        const { value } = event.target;
        const newReportParam = { ...reportParam };
        newReportParam.price_score_proportion = value;
        newReportParam.env_score_proportion = Math.round((1 - value) * 10) / 10;
        setReportParam(newReportParam);
    }

    const handleEmailChange = (event) => {
        const { name, value } = event.target;
        const newFormData = { ...formData, [name]: value };
        setFormData(newFormData);
    }

    const handleSubmit = async (event) => {
        // 直接禁用當前點擊的按鈕，立即生效
        event.target.disabled = true;
        event.preventDefault();
        const newFormData = { ...formData, ["param"]: reportParam };
        setFormData(newFormData);
        storeReport(newFormData);
        const resData = await post(apiUri.createReport, newFormData);
        if (resData.code === 0) {
            showAlert('報表產生成功', 'success');
        }
        handleClose();
    }

    const handleCollapsePriceGovToggle = () => {
        setCollapseOpenPriceGov(!collapsePriceGov);
    }

    const handleCollapseEnvToggle = () => {
        setCollapseOpenEnv(!collapseEnv);
    }

    // const handleEnvCollapseOpenIconToggle = (event) => {
    //     event.stopPropagation();  // 阻止事件冒泡
    //     setCollapseOpen(!collapseOpen);
    // }

    return (
        <BootstrapDialog
            maxWidth="xs" //'xs', 'sm', 'md', 'lg', 'xl' 或 false
            fullWidth={true}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={dialogShow}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                報表產生
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                {loading && <LoadingOverlay />}
                <Grid container rowSpacing={1.5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} padding={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography sx={{ alignItems: 'center' }}>
                            <InfoOutlinedIcon sx={{ color: '#1976d2' }} />
                            報表將於申請後約10分鐘，寄至以下信箱
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControl fullWidth>
                            <TextField fullWidth required
                                name="email"
                                id="email"
                                label="信箱"
                                value={formData.email}
                                onChange={handleEmailChange}
                                placeholder="someone@gmail.com.tw"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        {reportParam.target_object &&
                            <>
                                <Divider textAlign="center">
                                    <Typography sx={{ cursor: 'pointer' }} onClick={handleCollapsePriceGovToggle}>實價價格比較
                                        <IconButton
                                            // onClick={handleEnvCollapseOpenIconToggle}
                                            sx={{ height: 30 }}>
                                            {collapsePriceGov ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </IconButton>
                                    </Typography>
                                </Divider>
                                <Collapse in={collapsePriceGov} timeout="auto" unmountOnExit>
                                    <Grid container rowSpacing={1.5} columnSpacing={{ xs: 1, sm: 1, md: 1 }} paddingTop={2}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormControl required fullWidth>
                                                <InputLabel id="price_gov_proportion_select_label">分數佔比</InputLabel>
                                                <Select
                                                    labelId='price_gov_proportion_select_label'
                                                    name="price_gov_score_proportion"
                                                    id="price_gov_score_proportion"
                                                    label="分數佔比"
                                                    value={reportParam.price_score_proportion}
                                                    onChange={handleScoreProportionSelectChange}
                                                >
                                                    {
                                                        reportParam.price_score_proportion === 1
                                                            ? <MenuItem key={1} value={1}>100%</MenuItem>
                                                            : Object.entries(priceGovProportion).map(([key, value]) => (
                                                                <MenuItem key={value} value={value}>{key}</MenuItem>
                                                            ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormControl fullWidth>
                                                <TextField fullWidth required
                                                    name="unit_price_tw"
                                                    id="unit_price_tw"
                                                    label="欲購買物件單價(萬元)"
                                                    onChange={handleUnitPriceTwChange}
                                                    placeholder="80"
                                                    value={reportParam.target_object.unit_price_tw}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        {reportParam.env_category_weight_list && reportParam.env_category_weight_list.length > 0 &&
                            <>
                                <Divider textAlign="center">
                                    <Typography sx={{ cursor: 'pointer' }} onClick={handleCollapseEnvToggle}>機能權重設定
                                        <IconButton
                                            // onClick={handleEnvCollapseOpenIconToggle}
                                            sx={{ height: 30 }}>
                                            {collapseEnv ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </IconButton>
                                    </Typography>
                                </Divider>
                                <Collapse in={collapseEnv} timeout="auto" unmountOnExit>
                                    <Grid container
                                        rowSpacing={1.5}
                                        columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                                        paddingTop={2}
                                        justifyContent="center"  // 水平置中
                                        alignItems="center"      // 垂直置中
                                    >
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextField fullWidth required disabled
                                                name="env_score_proportion"
                                                id="env_score_proportion"
                                                label="機能分數佔比"
                                                value={`${reportParam.env_score_proportion * 100}%`}
                                            />
                                        </Grid>
                                        {
                                            reportParam.env_category_weight_list.map((item, index) => {
                                                return (
                                                    <Grid key={index} item xs={12} sm={12} md={12}>
                                                        <Grid
                                                            container
                                                            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                                                            paddingTop={1}
                                                            justifyContent="center"  // 水平置中
                                                            alignItems="center"      // 垂直置中
                                                        >
                                                            <Grid item xs={6} sm={6} md={6} sx={{ textAlign: 'center' }}>
                                                                <Typography >{item.name}</Typography>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6} sx={{ textAlign: 'left' }}>
                                                                <FormControl required fullWidth>
                                                                    <InputLabel id={`${item.name}_select_label`}>喜好程度</InputLabel>
                                                                    <Select
                                                                        labelId={`${item.name}_select_label`}
                                                                        name={item.name}
                                                                        id={item.name}
                                                                        label="喜好程度"
                                                                        value={item.weight}
                                                                        onChange={handleEnvSelectChange}
                                                                    >
                                                                        {Object.entries(likeLevelList).map(([key, value]) => (
                                                                            <MenuItem key={value} value={value}>{key}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Collapse>
                            </>
                        }
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus type="submit" onClick={handleSubmit}>
                    申請
                </Button>
            </DialogActions>
        </BootstrapDialog>
    )
}

export default React.memo(ReportDialog);