export const ageList = {
    // '不限': 0,
    //'預售屋': 1,
    '新屋(0~2年)': 2,
    '新古屋(2~15年)': 3,
    '中古屋(15~30年)': 4,
    '老屋(31年以上)': 5,
};

export const buildingShiftingTotalTwList = {
    '20坪以下': 1,
    '20~30坪': 2,
    '30~40坪': 3,
    '40~50坪': 4,
    '50坪以上': 5,
};

export const buildStateList = {
    //'不限': 'null',
    '住宅大樓(11層含以上有電梯)': '住宅大樓(11層含以上有電梯)',
    '公寓(5樓含以下無電梯)': '公寓(5樓含以下無電梯)',
    '套房(1房1廳1衛)': '套房(1房1廳1衛)',
    '華廈(10層含以下有電梯)': '華廈(10層含以下有電梯)',
    '透天厝': '透天厝'
};

// 住宅大樓(11層含以上有電梯)
// 倉庫
// 公寓(5樓含以下無電梯)
// 其他
// 套房(1房1廳1衛)
// 工廠
// 店面(店鋪)
// 廠辦
// 華廈(10層含以下有電梯)
// 辦公商業大樓
// 農舍
// 透天厝

export const shiftingFloorList = {
    '不限': 'null',
    '1樓': '1',
    '頂樓': '999',
};

export const buildingPatternRoomList = {
    '不限': 0,
    '1房': 1,
    '2房': 2,
    '3房': 3
};

export const priceGovProportion = {
    '70%': 0.7,
    '60%': 0.6,
    '50%': 0.5
}