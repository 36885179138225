export const travel = {
    '步行': 1,
    '汽機車': 2
};

export const walkingRadius = {
    '3分鐘路程(210公尺)': 210,
    '5分鐘路程(350公尺)': 350,
    '10分鐘路程(700公尺)': 700,
}

export const drivingRadius = {
    '3分鐘路程(2.1公里)': 2100,
    '5分鐘路程(3.5公里)': 3500,
    '10分鐘路程(7公里)': 7000,
}