
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DescriptionIcon from '@mui/icons-material/Description';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Box, Collapse, IconButton, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useState } from 'react';
import '../../css/styles.css';
import ReportDialog from './ReportDialog';
import SearchResult from './SearchResult';
import SearchResultDialog from './SearchResultDialog';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';


const bounceStyle = {
    animation: "bounce 0.5s infinite",
    "@keyframes bounce": {
        "0%, 100%": { transform: "translateX(0)" },
        "50%": { transform: "translateX(10px)" },
    },
    marginBottom: 1,
    bgcolor: 'red',
    '&:hover': { bgcolor: '#FF7080' }
};

export const SearchResultSideBar = () => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [zIndexGrid, setZindexGrid] = useState(2);
    const [zIndexIcon, setZindexIcon] = useState(3);
    const [reportDialogShow, setReportDialogShow] = useState(false);
    const [searchResultDialogShow, setSearchResultDialogShow] = useState(false);
    const theme = useTheme();
    const [iconPosition, setIconPosition] = useState({
        result: { top: 60, left: '51vw' }
    });
    const [isBouncing, setIsBouncing] = useState(true);
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesSM = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

    useEffect(() => {
        let newIconPosition;
        if (matchesXS) {
            newIconPosition = { result: { top: 60, left: '80vw' } };
            setIconPosition(newIconPosition);
        }
        else if (matchesSM) {
            newIconPosition = { result: { top: 60, left: '80vw' } };
            setIconPosition(newIconPosition);
        }
        else {
            newIconPosition = { result: { top: 60, left: '51vw' } };
            setIconPosition(newIconPosition);
        }
    }, [])

    const handleToggle = () => {
        const newZindexGrid = !isExpanded === true ? 2 : -1; //2:蓋過條件收合按鈕(1), -1:確保不會顯示
        const newZindexIcon = !isExpanded === true ? 3 : 1; //3:蓋過Grid(2), 1:顯示在地圖前面(0)
        setZindexGrid(newZindexGrid);
        setZindexIcon(newZindexIcon);
        setIsExpanded(!isExpanded);
    };

    // const handleShowCondition = () => {

    // }

    const handleShowReportDialog = () => {
        setIsBouncing(false);
        setReportDialogShow(true);
    }

    const handleReportDialogClose = () => {
        setReportDialogShow(false);
    }

    const handleShowSearchResultDialog = () => {
        setSearchResultDialogShow(true);
    }

    const handleSearchResultDialogClose = () => {
        setSearchResultDialogShow(false);
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={6} md={6} className='sideMenu' sx={{ zIndex: zIndexGrid, width: '100%' }}> {/* 在小屏幕上12列，中屏幕上6列，大屏幕上4列 */}
                    <Collapse
                        in={isExpanded}
                        sx={{ left: 0, border: '1px solid #1976d2', backgroundColor: 'white' }}
                    >
                        <SearchResult />
                    </Collapse>
                </Grid>
            </Grid>
            <Box sx={{ position: 'absolute', zIndex: zIndexIcon, width: '40px', top: iconPosition.result.top, left: isExpanded ? iconPosition.result.left : 10, transition: 'left 0.5s ease' }}>
                {/* {isExpanded && 
                    <Tooltip
                        title="切換查詢視窗"
                        placement="right"
                        sx={{
                            typography: 'body2',
                            backgroundColor: 'primary.dark',
                            color: 'white',
                            '& .MuiTooltip-arrow': {
                                color: 'primary.dark',
                            },
                        }}
                        arrow
                    >
                        <IconButton
                            sx={{
                                marginBottom: 1,
                                bgcolor: '#1976d2',
                                '&:hover': { backgroundColor: 'CornflowerBlue' }
                            }}
                            onClick={handleShowCondition}
                        >
                            <CompareArrowsIcon style={{ color: 'white' }} />
                        </IconButton>
                    </Tooltip>
                } */}
                <IconButton sx={{ marginBottom: 1, bgcolor: '#FFD306', '&:hover': { backgroundColor: '#FFE66F' } }}
                    onClick={handleToggle}
                >
                    {isExpanded ? <ChevronLeftIcon style={{ color: 'white' }} /> : <ChevronRightIcon style={{ color: 'white' }} />}
                </IconButton>
                <Tooltip
                    title="產生報表"
                    placement="right"
                    sx={{
                        typography: 'body2',
                        backgroundColor: 'primary.dark',
                        color: 'white',
                        '& .MuiTooltip-arrow': {
                            color: 'primary.dark',
                        },
                    }}
                    arrow
                >
                    <IconButton
                        sx={isBouncing ? bounceStyle : {
                            marginBottom: 1,
                            bgcolor: 'red',
                            '&:hover': { bgcolor: '#FF7080' }
                        }}
                        onClick={handleShowReportDialog}
                    >
                        <DescriptionIcon style={{ color: 'white' }} />
                    </IconButton>
                </Tooltip>
                {isExpanded &&
                    <Hidden smDown>
                        <Tooltip
                            title="全螢幕模式"
                            placement="right"
                            sx={{
                                typography: 'body2',
                                backgroundColor: 'primary.dark',
                                color: 'white',
                                '& .MuiTooltip-arrow': {
                                    color: 'primary.dark',
                                },
                            }}
                            arrow
                        >
                            <IconButton
                                sx={{
                                    marginBottom: 1,
                                    bgcolor: 'rgba(128, 128, 128, 1)',
                                    '&:hover': { bgcolor: '#ADADAD' }
                                }}
                                onClick={handleShowSearchResultDialog}
                            >
                                <FullscreenIcon sx={{ color: 'white' }} />
                            </IconButton>
                        </Tooltip>
                    </Hidden>
                }
            </Box>
            <ReportDialog dialogShow={reportDialogShow} handleClose={handleReportDialogClose} />
            <SearchResultDialog dialogShow={searchResultDialogShow} handleClose={handleSearchResultDialogClose} />
        </>
    )
}

export default React.memo(SearchResultSideBar);