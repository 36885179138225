import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAPI } from '../../context/APIContext';
import { LoadingOverlay } from '../../components/loading/Loading';
import { apiUris } from '../../config/Api';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Map Marker
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export const Login = (props) => {
  const line_icon = '/line-icon.svg';
  const fb_icon = '/facebook-icon.svg';
  const google_icon = '/google-icon.svg';
  const navigate = useNavigate();
  const { loading, handleLoginStatus, postFormData, post, getCookie, storeCookie, showAlert, removeCookie } = useAPI();
  const line_redirect_uri = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=2000296171&redirect_uri=${process.env.REACT_APP_LINE_REDIRECT_URI}&state=1qazRTGFDY5ysg111&scope=profile%20openid%20email`
  const google_redirect_uri = `https://accounts.google.com/o/oauth2/v2/auth?client_id=205555500595-vrlbp7rmq9c698p5dg735r7pg6obcba9.apps.googleusercontent.com&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URI}&state=12345abcde&response_type=code&scope=openid%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email`
  const [showPassword, setShowPassword] = useState(false);
  const [rememberEmail, setRememberEmail] = useState("");
  const [isRemeber, setIsRemember] = useState(false);

  useEffect(() => {
    const remember_email = getCookie("remember_email");
    if (remember_email && remember_email !== "") {
      setRememberEmail(remember_email);
      setIsRemember(true);
    }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    console.log({ email: formData.get('email'), pwd: formData.get('pwd') });
    login(formData);
  };

  const login = async (formData) => {
    const resData = await postFormData(apiUris.login, formData);
    if (resData.code === 0) {
      handleLoginStatus(true, resData);
      navigate('/', { replace: true });
    }
    else {
      navigate('/Login', { replace: false });
    }
  }

  const handleFBLogin = async () => {
    // 跳出 Facebook 登入的對話框
    window.FB.login(
      function (resAccessToken) {
        //saveAccessTokenFBFromAPI(response);
        console.log('handleFBLogin', resAccessToken);
        if (resAccessToken.status === 'connected') {
          window.FB.api('/me', { fields: 'last_name, first_name, name, email' }, function (resUserInfo) {
            console.log(JSON.stringify(resUserInfo));
            loginByFb(resAccessToken, resUserInfo);
          });
        } else {

        }
      },
      { scope: 'public_profile, email' }
    );
  };

  const loginByFb = async (resAccessToken, resUserInfo) => {

    const paramsObj =
    {
      email: resUserInfo.email,
      name: resUserInfo.name,
      access_token: resAccessToken.authResponse.accessToken,
      user_id: resAccessToken.authResponse.userID
    };

    let resData = await post(apiUris.memberLoginByFB, paramsObj);
    if (resData.code !== 0) {
      handleLoginStatus(false);
      navigate('/Login', { replace: false });
    }
    else {
      handleLoginStatus(true, resData);
      navigate('/', { replace: true });
    }
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleChangeRemember = () => {
    if (!rememberEmail || rememberEmail === "") {
      showAlert("請輸入信箱", "error");
    }
    else {
      const newIsRemeber = !isRemeber;
      setIsRemember(newIsRemeber);
      if (newIsRemeber === true) {
        storeCookie("remember_email", rememberEmail);
      }
      else {
        removeCookie("remember_email");
      }
    }
  }

  const handleChangeRememberEmail = (event) => {
    const { value } = event.currentTarget;
    setRememberEmail(value);
    if (isRemeber === true) {
      storeCookie("remember_email", value);
    }
  }

  return (
    <>
      {loading && <LoadingOverlay />}
      <ThemeProvider theme={defaultTheme}>
        <Grid container component="main" sx={{ height: '100vh' }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: 'url(/ad/車程水電.jpg)',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 2,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                登入
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="信箱"
                  name="email"
                  autoComplete="email"
                  value={rememberEmail}
                  onChange={handleChangeRememberEmail}
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="pwd"
                  label="密碼"
                  type={showPassword ? 'text' : 'password'}
                  id="pwd"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="記住帳號"
                  checked={isRemeber}
                  onChange={handleChangeRemember}
                />
                {/* <span style={{ color: 'red' }}>{errorMsg}</span> */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  登入
                </Button>
                <Grid container>
                  <Grid item xs={12} sm={6} md={6} sx={{ textAlign: 'center' }}>
                    <Link href="/PasswordForgetting" variant="body2">
                      忘記密碼?
                    </Link>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} sx={{ textAlign: 'center' }}>
                    <Link href="/Register" variant="body2">
                      {"帳號註冊"}
                    </Link>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} >
                    <Grid container columnSpacing={{ xs: 1, sm: 3, md: 8 }} sx={{ paddingTop: 3 }} alignItems="center" justifyContent="center">
                      <Grid item>
                        <a href={line_redirect_uri}>
                          <img src={line_icon} />
                        </a>
                      </Grid>
                      {/* <Grid item>
                        <a href="#" onClick={handleFBLogin}>
                          <img src={fb_icon} />
                        </a>
                      </Grid> */}
                      {/* <Grid item>
                        <a href={google_redirect_uri}>
                          <img src={google_icon} />
                        </a>
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider >
    </>
  );
}

export default React.memo(Login);
