import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const APIContext = createContext();

export const APIProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    //type:success,info,warning,error
    const [alert, setAlert] = useState({ open: false, message: '', type: 'info' });
    const [loginStatus, setLoginStatus] = useState(false);
    // const navigate = useNavigate();
    const baseUri = `${process.env.REACT_APP_API_DOMAIN}/api`;

    useEffect(() => {
        const token = getCookie("token");
        if (token && token !== "") {
            setLoginStatus(true);
        }
    }, []);

    const showAlert = (message, type = 'info') => {
        setAlert({ open: true, message, type });
    };

    const hideAlert = () => {
        setAlert({ ...alert, open: false });
    };

    const getConfig = () => {
        const token = getCookie("token");

        const config = {
            headers: {
                'Authorization': (!token || token === "") ? '' : `Bearer ${token}`,  // 使用 Bearer 方式加入 token，視後端需求而定
                'Content-Type': 'application/json',   // 確認內容類型，根據需求調整
                'version': `${process.env.REACT_APP_VERSION}`,
                'platform': `${process.env.REACT_APP_PLATFORM}`
            },
            withCredentials: true
        };

        return config;
    }

    const basePost = async (apiUri, paramsObj = null) => {
        const config = getConfig();
        const uri = `${baseUri}/${apiUri}`;
        console.log(uri);

        const res = await axios.post(uri, paramsObj, config)
            .then(response => { return response; })
            .catch(error => { throw error; });

        return res.data;
    }

    const basePatch = async (apiUri, paramsObj = null) => {
        const config = getConfig();
        const uri = `${baseUri}/${apiUri}`;
        console.log(uri);

        const res = await axios.patch(uri, paramsObj, config)
            .then(response => { return response; })
            .catch(error => { throw error; });

        return res.data;
    }

    const post = async (apiUri, paramsObj = null, callBack = null) => {
        try {
            setLoading(true);

            const resData = await basePost(apiUri, paramsObj);

            handleResponse(resData);

            if (callBack !== null && resData.code === 0) {
                callBack(resData);
            }

            return resData;
        } catch (error) {
            console.log(error);
            showAlert(error);
            return null;
        } finally {
            setLoading(false);
        }
    };

    const patch = async (apiUri, paramsObj = null, callBack = null) => {
        try {
            setLoading(true);

            const resData = await basePatch(apiUri, paramsObj);

            handleResponse(resData);

            if (callBack != null && resData.code === 0) {
                callBack(resData);
            }

            return resData;
        } catch (error) {
            console.log(error);
            showAlert(error);
            return null;
        } finally {
            setLoading(false);
        }
    };

    const deleteApi = async (apiUri, paramsObj = null, callBack = null) => {
        try {
            setLoading(true);

            const config = {
                headers: {
                    'Authorization': `Bearer ${getCookie("token")}`,  // 使用 Bearer 方式加入 token，視後端需求而定
                    'Content-Type': 'application/json',   // 確認內容類型，根據需求調整
                    'version': `${process.env.REACT_APP_VERSION}`,
                    'platform': `${process.env.REACT_APP_PLATFORM}`
                },
                data: paramsObj,
                withCredentials: true
            };

            const uri = `${baseUri}/${apiUri}`;
            console.log(uri);

            const resData = await axios.delete(uri, config)
                .then(response => { return response.data; })
                .catch(error => { throw error; });

            handleResponse(resData);

            if (callBack != null && resData.code === 0) {
                callBack(resData);
            }

            return resData;
        } catch (error) {
            console.log(error);
            showAlert(error);
            return null;
        } finally {
            setLoading(false);
        }
    }

    const postFormData = async (apiUri, formData = null, callBack = null) => {
        try {
            setLoading(true);

            const config = {
                headers: {
                    'Authorization': `Bearer ${getCookie("token")}`,  // 使用 Bearer 方式加入 token，視後端需求而定
                    'Content-Type': 'multipart/form-data',   // 確認內容類型，根據需求調整
                    'version': `${process.env.REACT_APP_VERSION}`,
                    'platform': `${process.env.REACT_APP_PLATFORM}`
                }
            };

            const uri = `${baseUri}/${apiUri}`;
            console.log(uri);

            const resData = await axios.post(uri, formData, config)
                .then(response => { return response.data; })
                .catch(error => { throw error; });

            handleResponse(resData);

            if (callBack != null && resData.code === 0) {
                callBack(resData);
            }

            return resData;
        } catch (error) {
            console.log(error);
            showAlert(error);
            return null;
        } finally {
            setLoading(false);
        }
    };

    const handleResponse = (resData) => {
        console.log(`code:${resData.code}, message:${resData.message}`);
        switch (resData.code) {
            case 0: //成功不動作
                break;
            case 105: //Token異常
                showAlert(resData.message, 'error');
                handleLoginStatus(false);
                break;
            case 106: //Token無效
                showAlert(resData.message, 'error');
                handleLoginStatus(false);
                break;
            case 112: //Token逾時
                showAlert(resData.message, 'error');
                handleLoginStatus(false);
                break;
            case 113: //權限不足
                showAlert(resData.message, 'error');
                break;
            case 114:
                showAlert(resData.message, 'error');
                break;
            default:
                showAlert(resData.message, 'error');
                break;
        }
    }

    const handleLoginStatus = (status, resData = null) => {
        if (status === false) {
            removeCookie("token");
            removeCookie("latest_access_type");
            removeCookie("role_ids");
            removeCookie("member_id");
            removeCookie("email");

            setLoginStatus(false);
            // navigate('/Login', { replace: false });
        }
        else {
            storeCookie("token", resData.token);
            storeCookie("latest_access_type", resData.latest_access_type);
            storeCookie("role_ids", resData.role_ids);
            storeCookie("member_id", resData.member_id);
            storeCookie("email", resData.email);

            setLoginStatus(status);
            // navigate('/', { replace: true });
        }
    }

    const handleApiParam = (event, paramsObj, setParamsObj) => {
        const { name, type, value, checked } = event.target;

        let inputValue;
        // 根据输入类型设置值：如果类型是 checkbox，则使用 checked；否则使用 value。
        switch (type) {
            case 'checkbox':
                inputValue = checked;
                break;
            default:
                //判斷 select "請選擇" 項目
                // inputValue = value === 'null' ? null : value;
                inputValue = value;
        }

        const newParamsObj = { ...paramsObj, [name]: inputValue };
        setParamsObj(newParamsObj);
    }

    const handleApiMutiParam = (values, paramsObj, setParamsObj) => {
        const newParamsObj = { ...paramsObj };
        values.map(item => {
            const { name, value } = item;

            newParamsObj[name] = value;
            return item;
        });
        setParamsObj(newParamsObj);
    }

    const storeCookie = (name, value, expires = 7) => {
        Cookies.set(name, value, { expires: expires });
    }

    const getCookie = (name) => {
        return Cookies.get(name);
    }

    const removeCookie = (name) => {
        return Cookies.remove(name);
    }

    return (
        <APIContext.Provider value={{
            loading,
            alert,
            post,
            postFormData,
            showAlert,
            hideAlert,
            loginStatus,
            handleLoginStatus,
            handleApiParam,
            handleApiMutiParam,
            patch,
            deleteApi,
            storeCookie,
            getCookie,
            removeCookie
        }}>
            {children}
        </APIContext.Provider>
    )
}

export const useAPI = () => useContext(APIContext);