import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { LoadingOverlay } from '../../components/loading/Loading';
import apiUri from '../../config/Api.js';
import { useAPI } from '../../context/APIContext';

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export const MemberInfo = () => {
    const { loading, post } = useAPI();
    const [memberInfo, setMemberInfo] = useState({
        name: null,
        email: null
    });

    useEffect(() => {
        getMemberInfo();
    }, []);

    const getMemberInfo = async () => {
        await post(apiUri.getMemberInfo, {}, (resData) => {
            setMemberInfo({
                name: resData.name,
                email: resData.email
            });
        });
    }

    return (
        <>
            {loading && <LoadingOverlay />}
            <ThemeProvider theme={defaultTheme}>
                <Grid container component="main" sx={{ height: '100vh' }}>
                    <CssBaseline />
                    <Grid item xs={12} sm={8} md={8} component={Paper} elevation={6} square>
                        <Typography variant="body1" sx={{ textAlign: 'center' }} gutterBottom>姓名 : {memberInfo.name}</Typography>
                        <Typography variant="body1" sx={{ textAlign: 'center' }} gutterBottom>Email : {memberInfo.email}</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={4}
                        sx={{
                            backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    />
                </Grid>
            </ThemeProvider>
        </>
    );
}

export default React.memo(MemberInfo);
