export const likeLevelList = {
    '普通': 1,
    '喜歡': 2,
    '很喜歡': 3,
};

export const hadLevelList = {
    '普通': 1,
    '討厭': 2,
    '很討厭': 3,
};
