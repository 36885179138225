import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React from 'react';

export const EmailVerifying = ({ verificationCode, onVerifyEmail, setVerificationCode }) => {
    return (
        <Box noValidate sx={{ mt: 1, width: '100%' }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="verification_code"
                label="驗證碼"
                name="verification_code"
                value={verificationCode}
                onChange={(event) => setVerificationCode(event.target.value)}
                autoFocus
            />
            <Button
                onClick={onVerifyEmail}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                送出
            </Button>
        </Box>
    )
}

export default React.memo(EmailVerifying);