import { Container, Typography } from '@mui/material';
import React from 'react';

const Policy = () => {
    return (
        <Container component="main" maxWidth="md" sx={{ mt: 16, marginBottom: 5 }} >
            <Typography variant="h5" component="h1" style={{ textAlign: 'center' }} gutterBottom>
                隱私權政策
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 3 }}>
                指址趣 Map Marker 網站（以下簡稱本網站）致力提供買房、開店等，有選址需求的使用者，全方位的需求分析，協助找到最佳的物件。當使用者使用本網站特定功能時，將要求您填寫個人相關資料，基於對個人資料保護及線上隱私權的支持，您所填寫的資料將受下列聲明保障：
            </Typography>
            <Typography variant="h6" gutterBottom>
                一、個人資料的規範
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 3 }}>
                本網站對會員個人資料的收集，遵守中華民國『個人資料保護法』之規範。
            </Typography>
            <Typography variant="h6" gutterBottom>
                二、個人資料蒐集
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 3 }}>
                使用者瀏覽本網站時，本網站不會在未明確告知的情況下，收集您的任何個人資料。在未成為會員的情況下，本網站僅蒐集瀏覽時位址及在相關網站內的瀏覽活動紀錄，以便我們進行網站流量和網路行為的分析，作為系統改善之依據，不涉及個人身分資料。使用者申請加入會員時，本網站會基於「完善會員服務」的立場，會要求填寫一些個人資料，如姓名、電子郵件等，以便後續聯繫、及提供更進一步的服務。
            </Typography>
            <Typography variant="h6" gutterBottom>
                三、個人資料應用
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 3 }}>
                本網站所取得的個人資料，都僅提供本網站使用，相關個人資料可於本網站營業存續期間內有權保留之。使用範疇均以使用者同意之項目進行應用。
            </Typography>
            <Typography variant="h6" gutterBottom>
                四、個人資料保護
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 3 }}>
                使用者個人機敏資料皆會透過加密方式保存，資料應用時僅有本公司經授權人員方可應用、嚴禁未經授權人員私自應用。
            </Typography>
            <Typography variant="h6" gutterBottom>
                五、外站連結
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 3 }}>
                本網站的網頁提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但該連結網站不適用本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。
            </Typography>
            <Typography variant="h6" gutterBottom>
                六、個人資料應用技術
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 3 }}>
                本網站使用 Cookie 技術，以便於提供更適合使用者需要的服務；是網站伺候器用來和使用者瀏覽器進行溝通的一種技術，它可能在使用者的電腦中儲存某些資訊，使用者可自由透過瀏覽器的設定，取消、或限制此項功能。
            </Typography>
            <Typography variant="h6" gutterBottom>
                七、隱私權政策聲明之修改
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 3 }}>
                本網站隱私權政策將因應需求隨時進行修正，修正後的條款將刊登於網站上。
            </Typography>
        </Container>
    );
};

export default React.memo(Policy);
