// 預定義 API URL
export const apiUris = {
    getEnvFilterItem: 'EnvCategory/GetEnvFilterItem',
    queryEnv: 'Env/Query',
    queryTempEnv: 'TempEnv/Query',
    queryCoordinateStatus: 'TempEnv/QueryCoordinateStatus',
    login: 'Login/MemberLogin',
    logout: 'Login/MemberLogout',
    SendEmail: 'Register/SendEmail',
    VerifyEmail: 'Register/VerifyEmail',
    CreateMember: 'Register/CreateMember',
    forgetPassword: 'Register/ForgetPassword',
    CreateMemberByAdmin: 'Register/CreateMemberByAdmin',
    updateTempEnv: 'TempEnv/Update',
    queryEnvCategory: 'EnvCategory/Query',
    getWithoutRootEnvCategory: 'EnvCategory/GetWithoutRoot',
    deleteTempEnv: 'TempEnv/Delete',
    createTempEnv: 'TempEnv/Create',
    getMemberInfo: 'Member/GetMemberInfo',
    memberLoginByLine: 'Login/MemberLoginByLine',
    memberLoginByFB: 'Login/MemberLoginByFB',
    memberLoginByGoogle: 'Login/MemberLoginByGoogle',
    queryHome: 'Home/Query',
    getTestWord: 'Test/GetTestWord',
    createReport: 'Report/Create',
    queryPriceGovByAddr: 'PriceGov/QueryByAddr'
};

export default apiUris;
