import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import React from 'react';
import { Typography } from '@mui/material';

export const EmailSending = ({ email, onSendEmail, setEmail }) => {
    return (
        <Box noValidate sx={{ mt: 1, width: '100%' }}>
            <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="信箱"
                name="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                autoFocus
            />
            <Typography variant="body2" sx={{ mt: 1 }}>
                繼續即表示您同意我們的「
                <Link href="/Policy" variant="body2">
                    隱私權政策
                </Link>
                」。
            </Typography>
            <Button
                onClick={onSendEmail}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                取得驗證碼
            </Button>
            <Link href="/Login" variant="body2">
                帳號登入
            </Link>
        </Box>
    )
}

export default React.memo(EmailSending);