import React from 'react';
import ReactDOM from 'react-dom/client.js';
import { BrowserRouter } from 'react-router-dom';
// import App from './App.js';
import App from './App';
//#region test component
// import Count from './Count.jsx';
//#endregion


const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
root.render( 
    <BrowserRouter>
        <App/>
    </BrowserRouter>
  );


