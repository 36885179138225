import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import React, { useState } from "react";
import ReportDialog from './ReportDialog.jsx';
import SearchResult from './SearchResult';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export const SearchResultDialog = ({ dialogShow, handleClose }) => {
    const [reportDialogShow, setReportDialogShow] = useState(false);

    const handleReportDialogClose = () => {
        setReportDialogShow(false);
    }

    return (
        <>
            <BootstrapDialog
                maxWidth="xl" //'xs', 'sm', 'md', 'lg', 'xl' 或 false
                fullWidth={true}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={dialogShow}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        padding: 0.125,
                        right: 26,
                        top: 8,
                        bgcolor: 'rgba(128, 128, 128, 1)',
                        '&:hover': { bgcolor: 'rgba(128, 128, 128, 0.6)' }
                    }}
                >
                    <FullscreenExitIcon sx={{ color: 'white' }} />
                </IconButton>
                <DialogContent dividers>
                    <SearchResult />
                </DialogContent>
            </BootstrapDialog>
            <ReportDialog dialogShow={reportDialogShow} handleClose={handleReportDialogClose} />
        </>
    )
}

export default React.memo(SearchResultDialog);