
import React, { useState } from 'react';

function isMobileDevice() {
  const mobileWidth = 768; // 设置移动设备的宽度阈值
  return window.innerWidth < mobileWidth;
}

export default function useCheckDeviceMobile() {
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  return isMobile;
}