import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export const PasswordResetting = ({ memberInfo, onResetPassword, setMemberInfo }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPwd, setShowConfirmPwd] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPwd = () => setShowConfirmPwd((show) => !show);

    return (
        <Box noValidate sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                name="pwd"
                label="密碼"
                type={showPassword ? 'text' : 'password'}
                id="pwd"
                value={memberInfo.pwd}
                onChange={(event) => setMemberInfo({ ...memberInfo, [event.target.name]: event.target.value })}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="confirm_pwd"
                label="確認密碼"
                type={showConfirmPwd ? 'text' : 'password'}
                id="confirm_pwd"
                value={memberInfo.confirm_pwd}
                onChange={(event) => setMemberInfo({ ...memberInfo, [event.target.name]: event.target.value })}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleClickShowConfirmPwd}
                                edge="end"
                            >
                                {showConfirmPwd ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Button
                onClick={onResetPassword}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                重設密碼
            </Button>
        </Box>
    )
}

export default React.memo(PasswordResetting);